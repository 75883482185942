// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Welcome {
  padding: 30px 0;
}
.Welcome .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.Welcome h2 {
  font-size: 32px;
}
.Welcome .MainActions {
  display: flex;
  gap: 60px;
}`, "",{"version":3,"sources":["webpack://./src/pages/UserPage/UserWelcome/UserWelcome.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AAAE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,SAAA;AAEJ;AAAE;EACE,eAAA;AAEJ;AAAE;EACE,aAAA;EACA,SAAA;AAEJ","sourcesContent":[".Welcome {\n  padding: 30px 0;\n  .container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 10px;\n  }\n  h2 {\n    font-size: 32px;\n  }\n  .MainActions {\n    display: flex;\n    gap: 60px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
